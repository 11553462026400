<template>
  <el-dialog
    :title="`选择${formworkName}`"
    width="824px"
    append-to-body
    :visible.sync="dialogVisible"
  >
    <div class="whole-warpper" v-loading="loading">
      <component :is="type" class="left" v-model="checkedList" :disabledIdList="disabledIdList"></component>
      <div class="right">
        <span class="search-warpper"
          >已选{{ formworkName }}：<span class="cancel-all" @click="cancelChecked()">(取消全部)</span></span
        >
        <div class="select">
          <el-checkbox-group v-model="checkedIdList">
            <el-checkbox
              v-for="item in checkedList"
              :key="item.value"
              :label="item.value"
              :disabled="disabledIdList.includes(item.value)"
              @change="cancelChecked(item)"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="oncancel"> 取消 </el-button>
      <loadingBtn type="primary" :isDisabled="!!!checkedIdList.length" @click="onsubmit"> 确定 </loadingBtn>
    </template>
  </el-dialog>
</template>

<script>
import { dialogComponentsMixin } from '@/mixins'
import formworkType from '@/views/fedex/formwork/mixin/formworkType'
import country from './country'
import province from './province'
import { map, findIndex } from 'lodash'

export default {
  components: {
    country,
    province
  },
  mixins: [dialogComponentsMixin, formworkType],
  props: {
    type: {
      type: String,
      default: 'country'
    },
    value: {
      type: Array,
      default: () => []
    },
    disabledList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      checkedList: [],
      checkedIdList: []
    }
  },
  computed: {
    disabledIdList() {
      return map(this.disabledList, 'value')
    }
  },
  watch: {
    dialogVisible() {
      this.checkedList = [...this.value]
    },
    checkedList: {
      handler(n) {
        this.checkedIdList = map(n, 'value')
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    cancelChecked(item) {
      if (item) {
        this.checkedList.splice(findIndex(this.checkedList, item), 1)
      } else {
        this.checkedList = [...this.disabledList]
      }
    },
    async onsubmit() {
      this.dialogVisible = false
      this.$emit('input', this.checkedList)
    },
    oncancel() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__header {
    padding: 20px 20px 10px;
    border-bottom: 1px solid #dcdee0;
  }
  .el-dialog__title {
    font-size: 14px;
    color: $color-title;
  }
  .el-dialog__body {
    padding: 0px;
  }
}
.whole-warpper {
  display: flex;
  height: 500px;
  .right {
    width: 296px;
    border-bottom: 1px solid #dcdee0;
    .cancel-all {
      color: #3841db;
      cursor: pointer;
    }
    .select {
      padding: 16px 0 0 16px;
      height: 420px;
      overflow: auto;
      .el-checkbox {
        color: #595961;
        font-weight: normal;
        margin-bottom: 13px;
      }
    }
    .search-warpper {
      display: inline-block;
      height: 56px;
      width: 100%;
      line-height: 56px;
      border-bottom: 1px solid #dcdee0;
      padding-left: 16px;
      color: #1a1a1a;
    }
  }
}
</style>
